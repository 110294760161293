@mixin blog_text {
  font-size: 1.3rem;
  line-height: 1.9rem;
  font-weight: 100;
}

.node-type-blog-page {
  h1.page-title {
    max-width: 1140px;
    margin-left:auto;
    margin-right: auto;
    color: #000000;
    border-bottom: none;
  }
  .field-name-field-hero-image {
    max-width: 840px;
  }
}

.section-blog  {
  .field-name-body, .views-field-body {
    max-width: 840px;
    @include blog_text;
  }
}

.page-blog {
  p {
    @include blog_text;
  }
}
#block-views-blog-list-blog-related {
  .view-content {
    display: flex;
    flex-wrap: wrap;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: 300;
    padding-left: 15px;
  }
}

.views-field-created {
  color: color('grey');
  font-size: 0.9rem;
  line-height: 1.3rem;
}

#block-views-blog-list-blog-thumbs {
  h2 {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 0;
  }
}

.view-display-id-blog_thumbs {
  .view-content {
    border-bottom: 1px dotted color('grey');
    display: flex;
    flex-wrap: wrap;
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
}

.view-display-id-blog_page {
  .view-content {
    max-width: 790px;
    margin-left: auto;
    margin-right: auto;
  }
  h2 a {
    color: #000000;
    text-decoration: none;
    font-weight: 400;
  }
}

.blog-thumb-link, .blog-title-link {
  a {
    text-decoration: none;
    color: #000000;
  }
}

.blog-hero-text {
  position: relative;
  background-color: #fff;
  top: 0;

  @include media-breakpoint-up(md) {
    position: absolute;
    top: 6%;
    padding: 2rem;
    right: 0;
    height: 86%;
    border: 1px solid color('grey');
  }

}

.view-display-id-blog_related {

}

.view-display-id-blog_hero, .view-display-id-blog_thumbs {
  .view-content {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
}

.blog-hero-inner {
  padding: 2px;
  border: 1px dotted color('grey');
  outline: 1px dotted color('grey');
  outline-offset: -8px;
  height: 100%;
  @include media-breakpoint-up(md)  {
    padding: 16px;
  }
}
