.page-toggler {
  @include media-breakpoint-down('md') {
    .header-bar {
      padding: 0;
    }
    .form-wrapper#edit-logo {
      padding: 0;
    }
  }

  .headline {
    background-color: color('grey');
    color: color('white');
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-size: 1.25rem;
    border-bottom: none;
  }

  .subheader {
    h4 {
      font-size: 1.25rem;
      line-height: 1.25rem;
      font-weight: 200;
      margin: .5rem auto .25rem;
    }

    p {
      font-size: .75rem;
      margin: .5rem auto;
      line-height: .75rem;
    }
  }

  main {
    top: 64px;
  }

  .region-navigation {
    .navbar {
      border: none;
    }
  }
}

#toggle-box {
  margin: 0 auto;

  text-align: center;
}

.ipad_bkg {
  overflow: visible;
  position: relative;
  box-sizing: content-box;
  /*&.show-controls,&.show-modal {
    padding-bottom: 2rem;
  }
  &.show-controls.show-modal {
    padding-bottom: 4rem;
  }*/
}

.carousel {
  height: 100%;

  &.show-controls {
    .carousel-indicators {
      display: flex;
      bottom: -4rem;
    }
  }

  &.show-controls.show-modal {
    .carousel-indicators {
      display: flex;
      bottom: -4rem;
    }
  }

  .carousel-indicators {
    display: none;
    margin: auto;

    li {
      width: 24px;
      height: 24px;
      flex: 0 0 auto;
      background-color: color('white');
      border: 1px solid color('grey');
      border-radius: 50%;
      cursor: pointer;
      box-sizing: content-box;

      &.active {
        width: 23px;
        height: 23px;
        background-color: color('grey');
        border: 1px solid color('white');
        box-shadow: 0 0 0 1pt color('grey');
      }
    }
  }
}

.carousel-inner {
  height: 100%;

  .carousel-item {
    vertical-align: top;
  }

  &.show-controls {
    height: 100%;
  }
}

#toggler-modal {
  position: absolute;
  height: 2rem;
  background-color: white;
  color: black;
  display: none;
  justify-content: center;
  align-items: center;
  bottom: -2rem;
  z-index: 2;
  width: 100%;

  &.show-controls {
  }

  .response-options {
    display: none;
  }

  .fa-chevron-up {
    font-size: 1rem;
    cursor: pointer;
  }

  &.active {
    display: flex;
    bottom: 0;
    //background-color: #E8318C;
    background-color: rgba(255, 255, 255, 0.5);
    border-top: 1px solid #000000;
  }

  &.modal-open {
    height: 6rem;
    padding-top: 0.5em;
    /*height overflow fix*/
    .form-wrapper {
      height: 100%;
      width: 100%;
    }

    .fa-chevron-up {
      display: none;
    }

    .close-response-modal {
      position: absolute;
      top: 0rem;
      right: 0rem;
      padding: 0.25rem;
      background: none;
      border: none;
      font-weight: bold;
      color: black;
    }

    .response-options {
      display: flex;
      white-space: nowrap;
      width: 100%;

      position: relative;

      span {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        white-space: normal;
        max-height: 100%;
        padding: 0;
      }

      .response {
        font-size: 3rem;
        padding: 0 0.5rem;
        display: inline-block;
        vertical-align: middle;

        &.selected {
          color: #E8318C;
        }

        &:hover {
          cursor: pointer;
        }
      }

      #edit-add-to-any-container {
        position: absolute;
        top: 0;
        right: 2em;
        width: auto;

        .addtoany_list {
          .a2a_button_facebook,
          .a2a_button_twitter,
          .a2a_button_google_plus,
          .a2a_button_pinterest,
          .a2a_button_whatsapp {
            display: none;
          }
        }
      }
    }

    &.response-sent {
      .response-options {
        #edit-modal-content-container {
          position: initial;

          #edit-thumbs-container {
            //display: none;
          }

          #edit-add-to-any-container {
            position: initial;
            z-index: 1;

            .addtoany_list {
              .a2a_button_facebook,
              .a2a_button_twitter,
              .a2a_button_google_plus,
              .a2a_button_pinterest,
              .a2a_button_whatsapp {
                //display: inline-block;
              }
            }
          }
        }
      }
    }

  }
}

.toggler-caption {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
  display: block;
  padding: 10px 0;
  text-align: center;
  margin: 0 auto;
  min-height: 44px;
}

#toggler-modal {
  position: absolute;
  height: 2rem;
  background-color: white;
  color: black;
  display: none;
  justify-content: center;
  align-items: center;
  bottom: -2rem;
  z-index: 2;
  width: 100%;

  &.show-controls {
  }

  .response-options {
    display: none;
  }

  .fa-chevron-up {
    font-size: 1rem;
    cursor: pointer;
  }

  &.active {
    display: flex;
    bottom: 0;
    //background-color: #E8318C;
    background-color: rgba(255, 255, 255, 0.5);
    border-top: 1px solid #000000;
  }

  &.modal-open {
    height: 6rem;
    padding-top: 0.5em;
    /*height overflow fix*/
    .form-wrapper {
      height: 100%;
      width: 100%;
    }

    .fa-chevron-up {
      display: none;
    }

    .close-response-modal {
      position: absolute;
      top: 0rem;
      right: 0rem;
      padding: 0.25rem;
      background: none;
      border: none;
      font-weight: bold;
      color: black;
    }

    .response-options {
      display: flex;
      white-space: nowrap;
      width: 100%;

      #edit-modal-content-container {
        width: 100%;
        position: relative;

        span {
          display: inline-block;
          text-align: left;
          vertical-align: middle;
          white-space: normal;
          max-height: 100%;
          padding: 0;
        }

        .response {
          font-size: 3rem;
          padding: 0 0.5rem;
          display: inline-block;
          vertical-align: middle;

          &.selected {
            color: #E8318C;
          }

          &:hover {
            cursor: pointer;
          }
        }

        #edit-add-to-any-container {
          position: absolute;
          top: 0;
          right: 2em;
          width: auto;

          .addtoany_list {
            .a2a_button_facebook,
            .a2a_button_twitter,
            .a2a_button_google_plus,
            .a2a_button_pinterest,
            .a2a_button_whatsapp {
              display: none;
            }
          }
        }
      }
    }

    &.response-sent {
      .response-options {
        #edit-modal-content-container {
          position: initial;

          #edit-thumbs-container {
            //display: none;
          }

          #edit-add-to-any-container {
            position: initial;
            z-index: 1;

            .addtoany_list {
              .a2a_button_facebook,
              .a2a_button_twitter,
              .a2a_button_google_plus,
              .a2a_button_pinterest,
              .a2a_button_whatsapp {
                //display: inline-block;
              }
            }
          }
        }
      }
    }

  }
}

.disclaimer {
  white-space: nowrap;

  &.show-controls {

    /*margin: 1rem 0 1rem;*/
  }
}

.logo-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;

  img {
    max-height: 100px;
    display: block;
    margin: 0 auto;
    @include respond-to('m') {
      max-width: 503px;
    }
    /*special fix for very small screens*/
    @media screen and (max-width: 410px) {
      max-width: 100%;
    }
  }
}

.toggler-wrapper {
  max-width: 350px;
  margin: 0 auto;
  @include respond-to('m') {
    max-width: none;
  }
}

#cycle-box {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 20px;
  left: 0;
  right: 0;
  pointer-events: none;

  button {
    pointer-events: all;
  }
}

.cycle-button {
  border: none;
  background-color: transparent;
  width: 100px;
  color: #B3B3B3;
  text-align: center;
  background-position: 50% 0;
  background-repeat: no-repeat;
  vertical-align: bottom;
  padding-top: 55px;
}

.image-status {
  padding-top: 1rem;
  font-weight: 100;
}

.before-image, .after-image {
  width: 100%;
  margin: 0 auto;
  cursor: pointer;

  img {
    width: 100%;
  }
}

.blank-icon {
  border: 2px solid #808080;
  border-radius: 50%;
}

.surgical .blank-icon, .non-surgical .blank-icon {
  width: 3rem;
  height: 3rem;
  display: inline-block;
  margin-right: 1rem;
  text-align: center;
  vertical-align: middle;
  font-style: normal;
  line-height: 3rem;
  font-size: 2rem;
}

.surgical span, .non-surgical span {
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: 100;
}

.divider-light {
  border-bottom: 1px #ebebeb solid;
}

.surgical .blank-icon, .surgical-color {
  color: #E8318C;
}

.non-surgical .blank-icon, .non-surgical-color {
  color: #197E9B;
}

.surgical, .non-surgical {
  margin-bottom: 2rem;
}

#next-image {
  display: block;
  background-image: url('../images/landing-next-photo.png');
  float: right;
}

#prev-image {
  display: block;
  background-image: url('../images/landing-prev-photo.png');
  float: left;
}

.arrow-keys {
  display: none;
  width: 400px;
  margin: 0 auto;
  border-top: 2px solid #808080;
  padding-top: 1rem;
  margin-top: 1rem;
  background-repeat: no-repeat;
  background-position: 0.5rem 1.4rem;
}

.page-icon a {
  margin-bottom: 1rem;
  text-align: center;
  display: block;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 100;
}

.page-icon a:hover .fa {
  background-color: rgba(232, 49, 140, 0.3);
  color: #ffffff;
  border: 2px solid #4d4d4d;
}

.page-icon a:hover {
  text-decoration: none;
  color: #808080;
}

#status {
  max-width: 431px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  @include respond-to('m') {
    margin-top: -1.2rem;
  }

}

.page-toggler {
  input.button, label.button {
    background-color: #E8318C;
    display: inline-block;
    color: #fff;
    border: none;
    padding: 20px 20px;
    border-radius: 3px;
    font-size: 1.3rem;
    -webkit-box-shadow: 1px 1px 1px 0 rgba(117, 117, 117, 1);
    -moz-box-shadow: 1px 1px 1px 0 rgba(117, 117, 117, 1);
    box-shadow: 1px 1px 1px 0 rgba(117, 117, 117, 1);
  }

  .icon-link:before {
    content: none;
  }

  .icon-link .fa {
    font-size: 2.4rem;
    border-radius: 50%;
    border: 2px solid;
    padding: 1.6rem;
    color: #4d4d4d;
    width: 6rem;
    height: 6rem;
  }
}

.toggler {
  text-indent: -9999px;
  border: none;
  width: 174px;
  height: 103px;
  outline: none !important;
  background: url('../images/toggler/before-after-input.png') no-repeat;
}

.toggler.on {
  background-position: 0 100%;
}

.two-column-toggler {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;

  & + .arrow-keys {
    display: none;
  }

  .toggler-wrapper {
    width: 100%;
    position: relative;
  }

  .thankyou-message {
    width: 100%;
    text-align: center;
    display: none;
    padding: 0;

    &.show {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
    }

    .thankyou-header {
      font-size: 2.25rem;
      width: 100%;
      display: block;
      text-align: center;
      font-weight: bold;
      margin-bottom: 2rem;
      line-height: 2.25rem;
    }

    .thankyou-subheader {
      font-size: 1rem;
      width: 100%;
      display: block;
      text-align: center;
    }
  }

  .toggler-physician-finder {
    width: 100%;
    text-align: center;

    .toggler-zipcode, .lead-nid {
      display: none !important;
    }

    .finder-results-header {
      font-size: 2rem;
      line-height: 2.2rem;
      margin: 0 auto 1.5rem;
      font-weight: 100;
    }

    .finder-results-subheader {
      font-size: 1rem;
      margin: 0 auto 1.5rem;
      font-weight: 100;
    }

    .finder-results-list {
      list-style: none;
      margin: 0 auto;
      display: block;
      padding: 0;
      width: 100%;

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-bottom: 0.75rem;

        &.none-found-message {
          text-align: center;
          display: block;
        }

        .finder-bullet {
          margin-right: 1rem;
          text-align: center;

          .result-number {
            width: 2.25rem;
            height: 2.25rem;
            background-color: #d6d6d6;
            text-align: center;
            color: #777;
            margin: 0 auto 0.5rem;
            border-radius: 50%;
            font-size: 1.5rem;
            line-height: 2.25rem;
          }

          .result-distance {
            color: #777;
            font-size: 0.8rem;
            line-height: 0.7rem;
          }
        }

        .finder-card {
          background-color: #FFF;
          border: 1px solid #d6d6d6;
          padding: 0.5rem 1rem;
          line-height: 1.05rem;
          text-align: left;
          width: 90%;
          min-height: 8rem;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          .result-doctor {
            color: #e6ae49;
            font-weight: bold;
            font-size: 1rem;
          }

          .result-practice {
            font-weight: bold;
            font-size: 0.9rem;
          }

          .result-address1 {
            font-size: 0.8rem;
          }

          .result-address2 {
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
          }

          .finder-button {
            background-color: color('burnt-orange');
            border-color: color('burnt-orange');
            padding: 0.2rem 0.4rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up('md') {
  .page-toggler {
    .headline {
      font-size: 1.5rem;
      background-color: transparent;
      color: color('black');
      font-weight: 200;
      border-bottom: 1px solid black;
    }

    .subheader {
      h4 {
        font-size: 2rem;
        margin: 1rem auto;
      }

      p {
        margin: 1rem auto;
      }
    }
  }
  .separator-right {
    border-right: 2px solid #808080;
  }
  #cycle-box {
    max-width: 700px;
    margin: 0 auto;
    position: absolute;
    top: 260px;
    left: 0;
    right: 0;
  }
  #status {
    color: #ffffff;
    position: absolute;
    margin: 0;
    left: 50px;
    top: 532px;
  }

  .arrow-keys {
    display: block;
  }
  .ipad_bkg {
    width: 503px;
    margin: 0 auto;
    background-repeat: no-repeat;
    position: relative;

    &.show-controls {
    }
  }

  .two-column-toggler {
    justify-content: space-between;

    .toggler-wrapper {
      width: 48%;
      min-width: 510px;

      #cycle-box {
        top: -165px;
        position: relative;
      }

      .ipad_bkg {
        width: 100%;
        height: auto;
        background-image: none;

        .before-image, .after-image {
          max-width: none;
        }
      }
    }

    .thankyou-message {
      width: 48%;
      height: 636px;
      min-width: 510px;
      padding: 1rem 3rem;
    }

    .toggler-physician-finder {
      width: 50%;
      min-width: 510px;

      .finder-results-list {
        width: 100%;

        li {
          .finder-card {
            height: auto;
            min-height: 0;

            .result-address2 {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
