
.filter-selection {
  .filter-select {
    background-color: transparent;
    border: none;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
  li {
    display: inline-block;
  }
}


.pcr-container {
  max-width: 1200px;
  margin: 0 auto;
}

.filter-item {
  width: 280px;
  height: 474px;
  padding: 10px;
  margin: 5px;
  float: left;
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  a, a:active, a:hover {
    text-decoration: none;
    color: color(grey-dark);
    /*height: 300px;*/
    display: block;
  }
}

.item-box {
  position: relative;
  background: color(grey-light);
  height: 100%;
  box-shadow: 0 0 3px #000000;
  border-radius: 4px;
  transition: all 0.5s ease-in-out 0s;
}

.item-title h3 {
  border-bottom:1px solid color(grey-dark);
  font-size:1.2rem;
  font-weight:100;
  line-height:1.5rem;
  color:color(grey-dark);
  margin:0;
  padding:10px 0 10px 0;
}

.item-details {
  overflow: hidden;
  padding:15px 15px 15px 20px;
}

.item-mask, .item-mask:hover {
  border-radius:4px;
  transition: all 0.5s ease-in-out 0s;

}

.filter-icon {
  width:40px;
  height:40px;
  margin-right: 15px;
  text-align: center;
  display:inline-block;
  background:color(pink);
  border-radius:50%;
  font-size: 20px;
  color:color(white);
  line-height:40px;
}


.item-brand,.item-mask {
  display:none;
}

.item-mask .item-caption {
  position: absolute;
  bottom: -60px;
  left: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  opacity: 0;
  width:100%;
}

.item-mask .item-caption p {
  display:block;
  font-size:16px;
  line-height:24px;
  color: color(white);
  text-align:center;
  font-weight:100;
  margin:0 auto;
  padding:0;
  text-transform:none;
}

.item-box:hover .item-mask .item-caption {
  bottom: 120px;
}

.container-filter {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 30px;
  padding: 0;
  text-align: center;
}