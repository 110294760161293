/*
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */

/* clearfix */
.owl-carousel .owl-wrapper:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
/* display none until init */
.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y;
}

.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.owl-carousel .owl-wrapper-outer.autoHeight {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item {
  float: left;
}

/*.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  cursor:;
}*/

.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
/*.grabbing {
  cursor: url(grabbing.png) 8 8, move;
}*/

/* fix */
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
}






/*
* 	Owl Carousel Owl Demo Theme
*	v1.3.3
*/

.owl-carousel .owl-controls {
  text-align: center;
}

/* Styling Next and Prev buttons */

.owl-carousel .owl-controls .owl-buttons div {
  cursor:pointer;
  display: inline-block;


}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-carousel .owl-controls.clickable .owl-buttons div:hover {
  filter: Alpha(Opacity=100); /*IE7 fix*/
  opacity: 1;
  text-decoration: none;
}

/* Styling Pagination*/

.owl-carousel .owl-controls .owl-page {
  display: inline-block;
  zoom: 1;
  *display: inline; /*IE7 life-saver */
}

.owl-carousel .owl-controls .owl-page span {
  display: block;
  width: 8px;
  height: 8px;
  margin: 0px 5px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #323232;
  border: 2px solid;
  transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  cursor: pointer;
}
/*.owl-carousel .owl-controls.clickable .owl-page:hover span,*/
.owl-carousel .owl-controls .owl-page.active span {
  background: rgba(0,0,0,0);
  border: 2px solid #323232;
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);

}

/* If PaginationNumbers is true */

.owl-carousel .owl-controls .owl-page span.owl-numbers {
  height: auto;
  width: auto;
  color: #FFF;
  padding: 2px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

/* preloading images */
.owl-item.loading {
  min-height: 150px;
  /*background: url(AjaxLoader.gif) no-repeat center center;*/
}

.owl-buttons {
  position: static;
}

.owl-carousel.white .owl-controls .owl-page span {
  background: none repeat scroll 0 0 #fff;
}

.owl-carousel.white .owl-controls .owl-page.active span {
  background: rgba(0, 0, 0, 0);
  border: 2px solid #fff;
}

.nf-carousel-theme {
}

.nf-carousel-theme .owl-controls .owl-pagination {
  position: absolute;
  z-index: 2;
  left: 0;
  text-align: center;
  width: 100%;
  margin-top: 15px;
}

.nf-carousel-theme .owl-controls .owl-buttons .owl-prev,
.nf-carousel-theme .owl-controls .owl-buttons .owl-next {
  width: 31px;
  height: 31px;
  line-height: 31px;
  font-size: 16px;
  margin-top: -15px;
}

.content-carousel .owl-controls .owl-buttons .owl-prev,
.content-carousel .owl-controls .owl-buttons .owl-next,
.image-slider .owl-controls .owl-buttons .owl-prev,
.image-slider .owl-controls .owl-buttons .owl-next,
.fullwidth-slider .owl-controls .owl-buttons .owl-prev,
.fullwidth-slider .owl-controls .owl-buttons .owl-next,
.nf-carousel-theme .owl-controls .owl-buttons .owl-prev,
.nf-carousel-theme .owl-controls .owl-buttons .owl-next {
  background: rgba(245,245,245,0.8);
  color: #252525;
  display: block;
  top: 50%;
  transition: all 0.25s ease 0s;
  -o-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  z-index: 3;
  position: absolute;
  text-align: center;
}

.content-carousel .owl-controls .owl-buttons .owl-prev:hover,
.content-carousel .owl-controls .owl-buttons .owl-next:hover,
.image-slider .owl-controls .owl-buttons .owl-prev:hover,
.image-slider .owl-controls .owl-buttons .owl-next:hover,
.fullwidth-slider .owl-controls .owl-buttons .owl-prev:hover,
.fullwidth-slider .owl-controls .owl-buttons .owl-next:hover,
.nf-carousel-theme .owl-controls .owl-buttons .owl-prev:hover,
.nf-carousel-theme .owl-controls .owl-buttons .owl-next:hover {
  /*background: #fff;*/
  color: #e6ae49;
}

.content-carousel .owl-controls .owl-buttons .owl-next,
.image-slider .owl-controls .owl-buttons .owl-next,
.fullwidth-slider .owl-controls .owl-buttons .owl-next,
.nf-carousel-theme .owl-controls .owl-buttons .owl-next {
  right: 0;
}

.nf-carousel-theme .owl-controls .owl-buttons .owl-prev {
  left: 0;
  margin-left: -51px;
  opacity: 0;
}

.nf-carousel-theme .owl-controls .owl-buttons .owl-next {
  right: 0;
  margin-right: -51px;
  opacity: 0;
}

.content-carousel:hover .owl-controls .owl-buttons .owl-prev,
.content-carousel:hover .owl-controls .owl-buttons .owl-next,
.image-slider:hover .owl-controls .owl-buttons .owl-prev,
.image-slider:hover .owl-controls .owl-buttons .owl-next,
.fullwidth-slider:hover .owl-controls .owl-buttons .owl-prev,
.fullwidth-slider:hover .owl-controls .owl-buttons .owl-next,
.nf-carousel-theme:hover .owl-controls .owl-buttons .owl-prev,
.nf-carousel-theme:hover .owl-controls .owl-buttons .owl-next {
  margin-right: 0;
  margin-left: 0;
  opacity: 1;
}

/*
OWL Controls
*/

.content-carousel .owl-controls .owl-pagination,
.image-slider .owl-controls .owl-pagination,
.fullwidth-slider .owl-controls .owl-pagination,
.item1-carousel .owl-controls .owl-pagination {
  position: absolute;
  z-index: 2;
  bottom: 15px;
  left: 0;
  text-align: center;
  width: 100%;
}

.content-carousel .owl-controls .owl-buttons .owl-prev,
.content-carousel .owl-controls .owl-buttons .owl-next,
.image-slider .owl-controls .owl-buttons .owl-prev,
.image-slider .owl-controls .owl-buttons .owl-next,
.fullwidth-slider .owl-controls .owl-buttons .owl-prev,
.fullwidth-slider .owl-controls .owl-buttons .owl-next {
  width: 51px;
  height: 51px;
  line-height: 51px;
  font-size: 21px;
  margin-top: -25px;
}

.content-carousel .owl-controls .owl-buttons .owl-prev,
.image-slider .owl-controls .owl-buttons .owl-prev,
.fullwidth-slider .owl-controls .owl-buttons .owl-prev {
  left: 0;
  margin-left: -51px;
  opacity: 0;
}

.content-carousel .owl-controls .owl-buttons .owl-next,
.image-slider .owl-controls .owl-buttons .owl-next,
.fullwidth-slider .owl-controls .owl-buttons .owl-next {
  right: 0;
  margin-right: -51px;
  opacity: 0;
}