.tabs__tab {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.tabs__tab-link:hover {
  text-decoration: none;
}

.layout-swap {
  @include respond-to('s') {
    padding-top: 0;
  }
}

.button, [type="button"], [type="reset"], [type="submit"] {
  @include primary-button();
  -webkit-appearance: none;
}

.btn-outline-primary {
  color: #E8318C;
  background-image: none;
  background-color: transparent;
  border-color: #E8318C;
  padding: 0.4rem;
  font-size: 1rem;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  padding-left:0;
  padding-right:0;
}

.form-item.form-type-textarea.form-item-field-address-affiliate-und-0-value {
  width: auto;
  float: none;
  textarea {
    width: auto;
    max-width: 100%;
  }
}

.no-term-reference-tree-button {
  background-color: transparent;
}

.pcr-physician-form {
  .form-item.form-type-textarea {
    float: none;
    margin: 0 auto;
  }
}