#workflow-steps{
  display: none;
  /*position: absolute;*/
  /*Opposite of header padding*/
  /*top: -16rem;*/
  width: 100%;
  margin: 2rem auto;
  @include respond-to ('m') {
    display: flex;
  }

}

#workflow-steps h4 {
  font-size: 24px;
  color: #a9a8ad;
  line-height: 30px;
  margin-bottom: 5px;
  text-transform: none;
  font-weight: 100;
  letter-spacing: 0;
  margin-top: 0.5rem;
}

#workflow-steps .row {
  max-width: 900px;
  margin: 0 auto;
}

.step-icon {
  width:73px;
  height:73px;
  display:inline-block;
  background-color:transparent;
  border:2px solid #4d4d4d;
  line-height:normal;
  text-align:center;
  vertical-align:middle;
  border-radius:50%;
}

#workflow-steps i.fa {
  font-size:30px;
  color:#323232;
  line-height:69px;
  text-transform:uppercase;
  text-align:center;
  vertical-align:middle;
  letter-spacing:0;
  :hover {
   background-color: color('grey');
    color: color(grey-dark);
  }
}

.connecting-dots {
  width:63%;
  height:73px;
  position:absolute;
  margin-left:63%;
  background-image:url(../images/connecting-dots.png);
  background-repeat:no-repeat;
  background-position:right;
  background-size:contain;
}