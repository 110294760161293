/*Single page designs have slightly larger text.*/
.scroller {
  letter-spacing: 0;
  font-weight: 100;
  h2 {
    font-size:2rem;
    line-height: 2.2rem;
    @include respond-to ('m') {
      font-size: 2.9rem;
      line-height: 3.2rem;
    }
    padding-bottom: 1rem;
    @extend .scroller;

  }
  h3 {
    font-size: 1.5rem;
    @include respond-to ('m') {
      font-size: 2rem;
    }

    @extend .scroller;
    .strong {
      @extend .strong
    }
  }
  h4 {
    font-size: 1.3rem;
    @extend .scroller;
    .strong {
      @extend .strong
    }
  }
  p {
    font-size: 1.3rem;
    @extend .scroller;
    .strong {
      @extend .strong
    }
  }
}


h2 {
  font-size: 2rem;
  font-weight: 100;
}

.embiggen {
  font-size: 1.2rem;
}

.headline {
  display: inline-block;
  border-bottom: 1px solid #000000;
  width: 100%;
}

.normal {
  font-size:1rem;
}

.thin {
  font-weight: 100;
  .form-item {
    font-weight: 100;
  }
  label {
    font-weight: 100;
  }
}

.strong,.thick {
  font-weight: 700;
}

.white {
  color: #ffffff;
}
.caps {
  text-transform: uppercase;
}

.xsmall {
  font-size: 0.8rem;

}
.faded {
  color:color(grey-dark);
}
.home-step h3 {
  font-size: 1.7rem;
  font-weight: 400;
  margin-bottom: 0;
}

.home-step p {
  margin-top: 0;
}