.view-affiliates {
  .view-header, .view-filters {
    padding-left: 1rem;
    padding-right: 1rem;
  }

}

.views-exposed-widgets, .view-header {
  padding-left: 1rem;
  padding-right: 1rem;
}

.views-field-field-email-editable {
  .views-row-edit-static {
    display: none;
  }
}

.views-exposed-form {
  .views-exposed-widget {
    .form-item {
      select {
        margin: 1.5rem 0;
      }
    }

    .form-submit {
      margin: 3rem 0 0;
      padding: 0.3rem 0.5rem;
    }
  }

  .views-widget {
    .form-item {
      margin: 1.5rem 0;
    }
  }
}

.row-highlight .time-lapse::before {
  display: inline-block;
  content: "•";
  color: #cccccc;
  font-size: 1.5rem;
}
