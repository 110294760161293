/*
html {
  overflow: hidden !important;
}
*/

#lead_status, #edit-field-assigned-simulator {
  display: inline-block;
  vertical-align: top;
  .form-item,#lead_status_check {
    display: inline-block;
    vertical-align: middle;
  }
}

.change-image-status-form {
  display: inline-block;
  width: 210px;
  padding: 0.5rem 0;
  input[type=submit] {
    font-size: 0.7rem;
    padding: 0.3rem 0.4rem;
    &:hover {
      font-size:0.7rem;
      padding: 0.3rem 0.4rem;
    }
  }
}
.page-node-manage {
  font-size: 0.9rem;
  .page-title {
    display: none;
  }
  #breadcrumb {
    display: none;
  }
  legend {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 1rem;
  }
  fieldset {
    border: none;
    padding: 0;
    width: auto;
    margin: 0;
  }
  .button-small-inverse, .button-small-inverse:visited, .button-small-inverse:active {
    background-color: #ffffff;
    text-decoration: none;
    display: inline-block;
    color: #E8318C;
    font-weight: 100;
    border: 1px solid #E8318C;
    padding: 10px 10px;
    border-radius: 3px;
    font-size: 0.9rem;
    -webkit-box-shadow: 1px 1px 1px 0 rgba(117,117,117,1);
    -moz-box-shadow: 1px 1px 1px 0 rgba(117,117,117,1);
    box-shadow: 1px 1px 1px 0 rgba(117,117,117,1);

  }

.manage-lead-button {
    font-size: 0.7rem;
    padding: 0.3rem 0.4rem;
    vertical-align: middle;
  
}
  .button-small-inverse:hover, .button-pink:hover, .button-small:hover {
    cursor: pointer;
  }

  .button-pink {
    background-color: color(pink);
  }

  .button-small-disabled {
    background-color: color(grey-dark);
    text-decoration: none;
    display: inline-block;
    color: #fff;
    font-weight: 100;
    border: none;
    padding: 10px 10px;
    border-radius: 3px;
    font-size: 1rem;
  }

  .button-small,.button-small:hover,.button-small:visited, .button-small:active {
    text-decoration: none;
    display: inline-block;
    color: #fff;
    font-weight: 100;
    border: none;
    padding: 10px 10px;
    border-radius: 3px;
    font-size: 1rem;
    @include button-shadow();
  }
  .form-item {
    margin: 0 0.5rem;
    vertical-align: middle;
  }
}

#lead-scroller, #photo-scroller {
  overflow: hidden;
}
#photo-scroller {
  margin-right: -0.9375rem;
  padding-right: 0.9375rem;
}


#lead-scroller:hover, #photo-scroller:hover {
  overflow-y: auto;
}


#lead-bar {
  height: auto;
  padding-bottom: 10px;
}

#columns {
  margin-top: -1rem;
}

#columns.unpad {
  margin-top: 0;
}

#DataTables_Table_0 {
  table-layout: fixed;
}

.views-field-field-before-collection .views-field-field-after-collection {
  width: 75px;
}

/*Lead node*/
.node-type-lead img.image-style-lead-images {
  width: 95%;
}


/*Not needed and an unbelievable pain to remove through the theme.*/
.grippie {
  display: none;
}

.original-swap {
  position: absolute;
  text-decoration: underline;
}

.separator {
  border-bottom: 1px solid #000000;
  padding-bottom: 2rem;
}

/* Manage Node */
.page-node.node-type-lead #content {
  /* border-top: 8px solid #323232; */
  padding: 0 0 0 0;
}

#wrapper-column-right {
  border-left: 3px solid #323232;
}

.change-image-status-form .form-item {
  /*margin: 0;
  padding: 0;*/
}




/* Left Sidebar */
.region-sidebar-first {
  border-right: 8px solid #323232;
}

.region-sidebar-first .region-inner {
  padding: 20px 20px;
}

.block-menu-block ul,
.block-menu-block ul.menu li {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  list-style: none;
}

/* containers */
#wrapper-columns {
  min-height: 500px;
}

#wrapper-column-left,
#wrapper-column-right {
  border-left: none;
  width: 100%;
  float: left;
  max-width: 500px;
  border-right: 8px solid #323232;
}

#wrapper-column-right {
  max-width: 616px;
  border-right: none;

}

/* ajax */

.page-node-manage {
  img.complete-check {
    display: inline-block;
    width: 20px;
    height: 18px;
  }

  fieldset legend {
    padding-top: 0.5rem;
    font-size: 1.2rem;
    font-weight: 700;
  }

  fieldset label {
    display: inline-block;
    font-weight: 300;
    width: 130px;
    text-align: right;
    padding-right: 20px;
    vertical-align: top;
  }

  label.left, .form-item-image-status label {
    text-align: left;
    width: 100%;
    /*Setup to match the current miscombobulation in manage*/
    font-size: 0.8rem;
    font-weight: 700 !important;
  }

  label {
    display: inline;
  }
  .dropzone {
    min-height: 80px;
  }

  .dropzone img, .original img {
    height: auto;
    max-height: 130px;
    width: auto;
  }

  .tall .dropzone, .tall .original img{
    height: 450px;
  }

  .tall .dropzone img {
    height: auto;
    max-height: 420px;
    width: auto;
  }

  .dropzone .dz-preview {
    margin: 0 !important;
  }
  .dropzone.dz-clickable {
    cursor: pointer;
  }

  .loading {
    background-image: url('../images/loading.gif');
  }

  .success {
    background-image: url('../images/check.gif');
  }

}


.shrinker, .clickable {
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}
.shrinker i, .clickable i {
  color: #E8318C;
}

.separator-right {
  border-right: 2px solid;
}

.shrinkable.shrunk {
  max-height: 3rem;
}
.shrinkable {
  max-height: 3000px;
  overflow: hidden;
  transition: max-height 0.25s;
}

.form-item-caption {
  display: inline-block;
  width: 75%;
}

.form_note_summary_button {
  margin-top: 1rem;
}

.form-item.form-type-textarea {
  width: 75%;
  float: left;
  clear: both;
}

.form-item label {
  clear: both;
}

input[name="caption"] {
  width: 100%;
}

/* image upload/status */
.div-float-left {
  width: 30%;
  float: left;
}

.div-float-left label {
  text-align: left;
  clear: both;
  display: block;
  width: auto;
}

.form-item-image-status {
  width: 63%;
  display: inline-block;
}

/* select */
.image-status-select {
  max-width: 100%;
}

.lead-photo-download {
  float: right;
  display: inline-block;
}

/*Bootstrap override*/
/*
#page.container {
  padding-left: 0;
  padding-right: 0;
  max-width: 1280px;
}
*/

.div-center {
  text-align: center;
}

.manage-preview {
  width: 90%;
}

.original-viz.show .dropzone {
  height: 150px !important;
  padding: 0 0 30px !important;
}


.feedback-icon {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  top: 10px;
  background-repeat: no-repeat;
  background-size: 66%;
}

.is-event-layout {
   position: relative;
   display: inline-block;
 }