ul.menu {
  list-style: none;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: color('grey');
}

.hamburger {
  position: absolute;
  top: 22px;;
  @include respond-to ('m') {
    display: none;
  }
}
.hamburger-inner,.hamburger-inner:before, .hamburger-inner:after {
  background-color: color(grey-dark);
}

nav a {
  text-decoration: none;
}

.bg-faded {
  border-bottom: 2px solid color(grey-light);
}

.region-navigation {

  .navbar {
    @include respond-to ('l') {
      height: 64px;
    }
    padding:0;
  }
  .nav-link.active-trail {
    color:color(grey-dark);
  }
  .nav-item {
    margin-bottom: 1rem;
    @include respond-to ('l') {
      justify-content:space-between;
      margin: 0 auto;
    }
  }
  .navbar-nav {
    margin-top:0;
    width: 100%;
    @include respond-to ('l') {
      text-align: center;
    }
    &.icons .nav-link{
      line-height: normal;
    }
  }
  .navbar-collapse {
    max-width: 100%;
    @include respond-to ('l') {
      max-width: 900px;
    }
    @include respond-to ('l') {
      margin: 0 auto;
    }
  }
  .navbar-brand {
    padding: 0;
  }
  .nav-link {
    padding-left: 0.5rem;
    @include respond-to('l') {
      padding-left: 2rem;
      padding-right: 2rem;
      height: 64px;
      line-height: 64px;
    }
    display: block;
  }
  .dropdown-menu {
    list-style: none;
    a {
      color: color(grey-dark);
    }
  }
  .fa {
    width: 45px;
    text-align: center;
    @include respond-to ('l') {
      width: auto;
      display: block;
    }
    margin-bottom: 5px;
    margin-left:auto;
    margin-right:auto;
    padding-top: 5px;
    padding-right: 5px;
  }
  .navbar-toggler {
    background-color: transparent;
    border: 2px solid #cccccc;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
  }
  .form-item {
    margin:0;
  }
  #search-block-form {
    width:290px;
  }
  .form-text {
    margin-left:10px;
  }
  .form-item-search-block-form, .form-actions {
    display: inline-block;
  }
  .form-item-search-block-form {
   margin-right:0.5rem;
  }
  .container-inline {
      display: flex;
      align-items: center;
  }
}