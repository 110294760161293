.small-space, .space-small {
  margin-bottom: 2rem !important;
}

.space {
  margin-bottom: 4rem !important;
}

.space-xsmall {
  margin-bottom: 1rem !important;
}

.space-xxsmall {
  margin-bottom: 0.5rem !important;
}

.space-top {
  padding-top: 4rem;
}

.space-top-small {
  padding-top: 2rem;
}

.space-top-xsmall {
  padding: 1rem;
}

.hide, .hidden {
  display: none !important;
}

.show {
  display: block;
}

.show-flex {
  display: flex;
}

.reset {
  margin: 0;
  padding: 0;
}

ul.reset {
  list-style: none;
}

.float-left {
  float: left;
  display: block;
}

.float-right {
  float: right;
  display: block;
}

.wide {
  width: 100% !important;
}

.wide-sm {
  width: 100%;
@include respond-to('m') {
  width: auto;
}
}

.pointer {
  cursor: pointer;
}

.phone-wide {
  width: 320px;
}

.font-small {
  font-size: 0.8rem;
}
.font-thin {
  font-weight: 100;
}

.color-black {

}

.small {
  font-size: 0.8rem !important;
}

.squeeze {
  width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.squeeze-xl {
  max-width: map_get($container-max-widths,'xl') ;
  margin-left: auto;
  margin-right: auto;
}

.squeeze-lg {
  max-width: map_get($container-max-widths,'lg') ;
  margin-left: auto;
  margin-right: auto;
}

.squeeze-md {
  max-width: map_get($container-max-widths,'md') ;
  margin-left: auto;
  margin-right: auto;
}

.font-size-2-75-rem {
  font-size: 2.75rem;
}
.font-size-2-5-rem {
  font-size: 2.5rem;
}
.font-size-2-25-rem {
  font-size: 2.25rem;
}
.font-size-2-rem {
  font-size: 2rem;
}
.font-size-1-75-rem {
  font-size: 1.75rem;
}
.font-size-1-5-rem {
  font-size: 1.5rem;
}
.font-size-1-25-rem {
  font-size: 1.25rem;
}
.font-size-1-rem {
  font-size: 1rem;
}
.font-size-0-75-rem {
  font-size: 0.75rem;
}
.font-size-0-5-rem {
  font-size: 0.5rem;
}

.color-black {
  color: #000000 !important;
}
.decorate-none {
  text-decoration: none !important;
}

.no-border {
  border: none !important;
}
.bg-grey {
  background-color: color(footer);
}
