.affiliate-logo {
  display: flex;
  max-width: 700px;
  min-height: 234px;
  justify-content: space-around;
  margin: 1rem auto;
  &.workflow-logo {
    max-width: 100%;
  }
}

.affiliate-logo img { 
  align-self: center;
}


//fix to make fonts lighter on visualizer page
.light-font {
  h1,h3 {
    font-weight: 300;
  }
}

.page-neo {
  .form-type-checkbox {
    white-space: nowrap;
    label {
      white-space: nowrap;
    }
  }
}

.page-pcr-, .page-ulthera, .page-neo, .page-neograft {
  .form-type-file, input[value="upload"] {
    display: none;
  }
  .form-type-checkbox {
    label {
      font-size: 1.4rem;
      font-weight: 100;
      padding-left: 10px;
    }
  }
}

.page-ulthera-consumer,.page-neo-consumer-,page-neograft-consumer- {
  .form-type-checkbox {
    margin-bottom: 3.3rem;
    padding-top: 1rem;
  }
}

.page-neo-consumer-,.page-neograft-consumer- {
  .concern-wrapper {
    margin-top: 1.4em;
    margin-bottom: 1.4em;
    /*border: 1px solid $neograft-grey;*/
  }
}

.alternate-wrapper {
  display: none;
}
.active .alternate-wrapper {
  display: block;
  position: absolute;
  width:100%;
  top:0;
}

.page-ulthera,.page-neo, .page-neograft {
  h2 {
    padding-bottom: 1rem;
    border-bottom: 2px solid $ultherapy-grey;
  }
  .form-type-checkboxes {
    margin-bottom: 1.5rem;
  }
  label[for="edit-aoc1"], label[for="edit-aoc2"], label[for="edit-aoc3"] {
    font-size: 1.8rem;
    font-weight: 100;
    margin-bottom: 2rem;
  }

  .btn-primary {
    @include button-variant('#ffffff', $ultherapy-primary, $ultherapy-primary);
    &.inactive {
      @include button-variant('#000000', $ultherapy-grey, $ultherapy-grey);
    }
  }
  input[type="text"],input[type="password"],input[type="email"],input[type="tel"], select {
    &:focus {
      border-color: $ultherapy-primary;
      outline: none;
    }
  }
  .btn.intensity {
    @include button-variant('#000000', $ultherapy-grey, $ultherapy-grey);
  }
  .active {
    .btn.intensity {
      @include button-variant('#ffffff', $ultherapy-primary, $ultherapy-primary);
    }
  }
  .row img {
    display: block;
    margin:0 auto;
  }
}

.page-ulthera {
  .button, .btn {
    text-transform: capitalize;
  }
}
.page-neo, .page-neograft,.page-ulthera,.page-pcr {
  h1 {
    border-bottom: 1px solid #6e6e71;
    font-weight: 300;
    padding: 10px 15px;
    margin-bottom: 2rem;
    color: #6e6e71;
    margin-top: 0;
  }
}
.page-neo, .page-neograft,.page-ulthera {

  h2 {
    border-bottom: 2px solid color('grey');
  }  
  .btn-primary {
    @include button-variant('#ffffff', $neograft-primary, $neograft-primary);
    &.inactive {
      @include button-variant('#000000', $neograft-grey, $neograft-grey);
    }
  }  
  input[type="text"],input[type="password"],input[type="email"],input[type="tel"], select {
    &:focus {
      border-color: $neograft-primary;
    }
  }
  .btn.intensity {
    @include button-variant('#000000', $neograft-grey, $neograft-grey);
  }
  .active {
    .btn.intensity {
      @include button-variant('#ffffff', $neograft-primary, $neograft-primary);
    }
  }
}

label {
  font-weight: 100;
}

.form-item {
  label {
    text-transform: capitalize;
  }
}


/* Extra buttons we don't need because they're automated by JS*/
.form-type-file, input[value="upload"] {
  display: none !important;
}

.page-pcrapi .form-item.form-type-textarea {
  width: 100%;
  float: none;
}

.page-pcr-physician, .page-pcrapi  {
  #edit-photos, #edit-photo{
    background: url("../images/fileupback.png") center top no-repeat;
    width: 815px;
    height: 250px;
    padding: 300px 10px 10px 10px;
    color: white;
    position: relative;
    margin-top: -14px;
    overflow:hidden;
  }

  .form-type-file, input[value="upload"] {
    display: none;
  }
  h2{
    padding-bottom: 1rem;
    border-bottom: 2px solid color(grey-dark);
  }
  label.error {
    color: red;
    font-weight: bold;
  }
  label#edit-photos-error {
    display: block;
  }

  .form-item-photos-{
    overflow: hidden;
    padding-top: 40px;
    height:215px;
  }
}

.aoc-notes-desc{
  font-size: 16px;
  width: 70%;
  margin: 0 auto;
}


#gallery .thumbnail {
  display:inline-block;
  width:250px;
  padding:10px;
}

.mobile-button {
  display: block;
  background-image: url(/sites/all/themes/newlooknow/images/ultherapy-mobile-sprite.png);
  background-repeat: no-repeat;
  height: 100px;
  background-size: 340px auto;
  margin-bottom: 2rem;
}
.mobile-button:hover {
  cursor: pointer;
}

.workflow-thankyou-message {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.75rem;
  margin: 3rem 0;
}

#eyebrow.inactive {
  background-position: 0 -1347px;
}

#chin.inactive {
  background-position: 0 -1122px;
}

#neck.inactive {
  background-position: 0 -1571px;
}

#deco.inactive {
  background-position: 0 -897px;
}

#eyebrow.active {
  background-position: 0 -450px;
}

#chin.active {
  background-position: 0 -224px;
}

#neck.active {
  background-position: 0 -674px;
}

#deco.active {
  background-position: 0 0;
}
.photo-fid {
  margin: 0 auto 0.3rem;
  display: block;
}

.page-ulthera {
  .tooltip {
    background-color: color(black) !important;
    color: color(white) !important;
    max-width: 80% !important;
    padding: 1rem !important;
    @include respond-to('m') {
      max-width: 50% !important;
    }
  }
  .ulthera-agreement-fields {
    border: none !important;
    label {
      font-size: .85rem;
    }
    input[type="checkbox"] {
      vertical-align: middle;
    }
    .form-type-checkbox {
      margin-bottom: 1rem;
    }
  }
}

/*New Physician workflow*/

.step2 {
  .form-type-checkbox-tree  {
    & > label {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    .no-term-reference-tree-button,.term-reference-tree-button {
      display: none;
    }
    .form-type-checkbox {
      margin-left: 16px !important;
      input[type="checkbox"],label {
        vertical-align: middle;
      }
    }
  }
  .form-item.form-type-textarea {
    float: none;
    margin: 0 auto;
  }
}
ul.term-reference-tree-level {
  margin-bottom: 2rem;
}

.breast-details-section {
  width: 90%;
  margin: 0 auto;
  .form-item {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    label {
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

/*physician-list pages */
.physician-list {
  img {
    height: 250px;
  }
  ul {
    list-style: none;
    width: auto;
    li {
      .finder-bullet {
        background-color: color('grey');
        color: color('grey-dark');
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
      }
      .physician-info {
        width: 375px;
        /*border: 1px solid color('grey');*/
        .physician-name {
          color: color(pink);
        }
        .btn {
          background-color: color(pink);
          border-color: color(pink);
        }
      }
    }
  }
}
