.section-dashboard {
  h1.page-title {
    display: none;
  }
}

.container.dashboard {
  max-width: 1124px;
  color: color(white);
  padding-top: 4rem;
  margin-bottom: 4rem;
  .col-md-3 {
    padding-left: 0;
    padding-right: 0;
  }
  h3 {
    left: 0;
    padding-top: 0.5rem;
    position: absolute;
    width: 100%;
    text-align: center;
  }
  a {
    color: color(white);
    text-decoration: none;
    height: 100%;
    display: flex;
    justify-content: center;
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  p {
    /*IE Needs this.*/
    width: 100%;
  }
}

.dashboard-counter {
  border: 6px double;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  line-height: 88px;
  font-size: 2rem;
  align-self: center;
}

.dashboard-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.dashboard-wrapper {
  display: block;
  position: relative;
  height: 200px;
  background-color:rgba(0,0,0,0.7);
}

.dashboard-top {
  font-family: 'GandhiSerif', "Book Antiqua", Palatino, "Palatino Linotype", "Palatino LT STD", Georgia, serif;
  .dashboard-wrapper {
    border: 4px solid color(grey-light);
    background-image:none;
    @include respond-to('m') {
      height: 306px;
      background-image: url('../images/affiliate/affliate_home_bg_2new.png');
      background-repeat: no-repeat;
      border-left: 8px solid color(grey-dark);
      border-top: 8px solid color(grey-dark);
      border-bottom: 8px solid color(grey-dark);
      border-right: none;
    }
  }
}

.dashboard-bottom {
  .dashboard-wrapper {
    background-image:none;
    border: 4px solid color(grey-light);
    @include respond-to('m') {
      height: 306px;
      background-image: url('../images/affiliate/affiliate-3-bg.png');
      background-repeat: no-repeat;
      border-left: 8px solid color(grey-dark);
      border-bottom: 8px solid color(grey-dark);
      border-top: none;
      border-right: none;
    }
  }
}

.credits-display {
  left:0;
  display: block;
  color: #333;
  font-size: 24pt;
  text-align: center;
  width: 95%;
  height: 40px;
  line-height: 40px;
  background: transparent url("../images/affiliate/credits_display_bg.png") center center no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
}

#dashboard-leads {
  background-position: 0 0;
}

#dashboard-ready {
  background-position: -285px 0;
}

#dashboard-quick {
  background-position: -567px 0;
}

#dashboard-piq {
  background-position: -856px 0;
  @include respond-to ('m') {
    border-right: 8px solid color(grey-dark);
  }
}

#dashboard-credits {
  background-position: 0 0;
}

#dashboard-credits {
  background-position: -2px 0;
}

#dashboard-toolkit {
  background-position:-360px 0;
}

#dashboard-search {
  background-position: -716px 0;
}
#dashboard-help {
  background-position: -1072px 0;
}

#dashboard-help {
  @include respond-to ('m') {
    border-right: 8px solid color(grey-dark);
  }
}


