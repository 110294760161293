@mixin primary-button ($bg-color:$nln-primary) {
  background-color: $bg-color;
  border: 1px solid transparent;
  display: inline-block;
  color: color(white);
  font-weight: 100;
  text-align: center;
  /*border: none;*/
  padding: 1rem;
  border-radius: 3px;
  /*font-size: 1.3rem;*/
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
}

@mixin button-shadow () {
  -webkit-box-shadow: 1px 1px 1px 0 rgba(117,117,117,1);
  -moz-box-shadow: 1px 1px 1px 0 rgba(117,117,117,1);
  box-shadow: 1px 1px 1px 0 rgba(117,117,117,1);
}

@mixin bg-image {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
}

/*Bootstrap form control converted to mixin.*/
@mixin form-input () {
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #55595c;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0.25rem;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius);
  @include box-shadow($input-box-shadow);
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  &::placeholder {
    color: color('grey');
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
    font-weight: 100;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-bg-disabled;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  &:disabled {
    cursor: $cursor-disabled;
  }

  &:focus {
    border-color: color(pink);
    outline: none;
  }
}

main[role="main"] {
  min-height: 800px;
}
.page-user main[role="main"] {
  padding: 2rem;
}

a {
  color: color(pink);
}

a.btn {
  text-decoration: none;
}

.navbar-brand {
  height: 64px;
}

.btn-ultherapy {
  @include primary-button($ultherapy-primary);
  @include button-shadow();
}
.btn-link-ultherapy {
  color:color(grey-dark);
  background-color:transparent;
  border:none;
}

main[role="main"] {
  position: relative;
  top: 64px;
  margin-bottom: 100px;
}

.pink {
  color:color(pink);
}

.btn {
  font-weight: 100;
  padding: 1rem;
}
.btn-phone {
  width: 320px;
}

ol.search-results::before {
  float: left;
}

.search-results {
  .title {
    margin-bottom: 0;
  }
  .search-snippet-info {
    margin-left: 1rem;
  }
}

.expedited-Expedited, .expedited-expedited  {
  background-color:rgb(242, 215, 225);
}

.page-search {
  #edit-basic {
    @include clearfix();
    padding-top: 1rem;
  }
  .form-item-keys {
    float: left;
  }
  #edit-keys {
    width: 320px;
    margin-right: 1rem;
  }

  #edit-submit {
    padding: 0.4rem;
  }
  .form-item {
    margin:0;
  }
  a {
    color:color(grey-dark);
  }
  main h2 {
    display: none;
  }
  label[for="edit-keys"] {
    display: none;
  }
  h1.page-title {
    display: none;
  }
  ol > li::before {
    float: left;
  }

}

header.header {
  position: relative;
  z-index: 200;
}

.not-front header {
  /*height: 80px;*/
}

main {
  margin-bottom: 2rem;
}

.not-front h1.page-title {
    border-bottom: 1px solid #6E6E71;
    font-weight: 300;
    padding: 10px 15px;
    margin-bottom: 2rem;
    color: #6E6E71;
    margin-top: 0;
}


/*Adding back in for specific section*/
.page-affiliate-add-event h1.page-title {
  display: block;
}

input[type="text"],input[type="password"],input[type="email"],input[type="tel"], select {
  @include form-input();
}

.display-label-inline label, .display-label-inline input[type="text"],.display-label-inline input[type="email"],.display-label-inline input[type="tel"], .display-label-inline select {
  display: inline-block;
}

.form-item .description {
  font-weight: 100;
}

/*.button-primary, .form-submit {
  @include primary-button();
  @include button-shadow();
  text-align: center;
  margin-right:0.25rem;
  width: 100%;

  @include respond-to ('s') {
    width: auto;
  }
}*/

.region-header .block-superfish {
  display: block;
  float: right;
}

.sf-menu a {
  text-decoration: none;
  color:#000000;
  line-height: 2.5rem;
}


.sf-menu li {
  padding: 0 0.5rem;
}

.sf-menu li:hover > ul, .sf-menu li.sfHover > ul {
  top: 134px;
  border: 1px solid #000;
  border-top: none;
  background: #fff;
}

.region-header ul.menu li.sf-depth-1 a.sf-depth-1, .region-header ul.menu li.sf-depth-1 a.sf-depth-1:visited {
  display: block;
  text-align: center;
  vertical-align: top;
  line-height: 10pt;
  font-size: 0.8rem;
  text-transform: lowercase;
}

ul.menu span.nolink {
  /*padding: 10px 15px;*/
  font-weight: 700;
  color: #000;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.menu-border {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px solid;
  width: 5em;
  height: 5em;
  margin-bottom: 1rem;
}

.button-shadow, .btn-shadow, .btn-primary {
  @include button-shadow();
}

.bg-image {
  @include bg-image;
}

.bg-strip {
  padding-bottom: 50px;
  padding-top: 50px;
  margin-bottom: 2rem;
}

hr {
  margin-bottom: 0;
  margin-top: 0;
  border-top: 1px solid color(grey-light);
  border-bottom: none;
  border-left: none;
  border-right: none;
}
hr.dark {
  border-top: 1px solid color(grey-dark);
}

.gap-lg {
  padding-top: 2rem;
  margin-bottom: 2rem;
}

.gap {
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.gap-sm {
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.space-sm {
  margin-bottom: 1rem;
}
.space {
  margin-bottom:2rem;
}
.space-lg {
  margin-bottom: 4rem;
}

.list-inline li {
  display: inline-block;
}

.fa {
  cursor: default;
}

a .fa, button .fa {
  cursor: pointer;
}

.button {
  @include  primary-button;
  width:330px;
  margin: 0 auto;
}

.loading-img {
  width: 100px;
  margin: 0 auto;
}

.region.region-bottom {
  margin-top:-32px;
  background: color(footer);
  color: color(grey-dark);
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 0.8rem;
  font-weight:100;
  .form-item {
    margin:0;
  }
  ul {
    padding: 0;
    @include respond-to('s') {
      padding: inherit;
    }
  }

  p{
    text-align: center;
    margin:0;
  }
  .block-menu {
    ul li {
      display: inline-block;
      margin-left: 10px;
    }
  }
  h2.block__title {
    font-weight: normal;
    font-size: 1.2rem;
    float: left;
    border-left: 1px solid color(white);
    border-right: 1px solid color(white);
    padding: 0 10px;
    line-height: 1rem;
  }
  a,a:visited {
    color:color(grey-dark);
  }
}

ol {
  counter-reset: li;
  margin-left: 0;
  padding: 0;
}

ol > li {
  list-style: none;
  line-height: 2rem;
  margin-bottom: 0.5rem;
}

ol > li::before {
  content: counter(li);
  counter-increment: li;
  top: 0.6rem;
  margin-right: 8px;
  color: #fff;
  background: #A7AAAB;
  text-align: center;
  border-radius: 50%;
  height: 1.7rem;
  width: 1.7rem;
  line-height: 1.7rem;
  font-size: 1.1rem;
  font-weight: 100;
  display: inline-block;
}
.search-results ol > li::before {
  float: left;
}

.search-results {
  p {
    margin:0;
  }
}
.search-snippet-info {

}

#block-masquerade-masquerade {
  padding-bottom: 5rem;
  h2.block__title {
    display: none;
  }
}
.form-item-masquerade-user-field {
  display: inline-block;
  margin-right: 1rem;
}

#masquerade-block-1 {
  .container-inline {
    display: flex;
    align-items: center;
  }
}

/**
@todo what is the below for? We may never know. Delete?
 */

/*element {
  padding: 0 1rem;
  margin: 2rem auto;
  max-width: 1140px;
}*/

#edit-caption {
  min-height: 1.5rem;
}

.navbar-horizontal .sticky-header {
  top: 38px !important;
  z-index: 100;
}

.navbar-tray-open.navbar-horizontal .sticky-header {
  top: 80px !important;
  z-index: 100;
}

//widget code
.nln-widget-fields {
  display: none;
  &.fields-active {
    display: block;
  }
}

.nln-widget-theme-builder {
  .hidden {
    display: none !important;
  }
}

.colorpicker {
  input {
    background: transparent !important;
    border: 1px solid transparent !important;
    font-size: 10px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

/*Boostrap version hack remove once we're fully on v4.*/
.alert-dismissible .close {
  background: transparent;
  position: absolute;
  top: 0;
  right: 20px;
  padding: 0.75rem 1.25rem;
  color: inherit;
  border: 0;
}
.footer-bar {
  margin-bottom: 2rem;
}
