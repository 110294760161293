#home-hero {
  padding-top: 65px;
  overflow: hidden;
  @include respond-to ('s') {
    background-image: url("../images/home/hero_background@2x-80.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
  }
  @include respond-to ('m') {
    margin-top: -65px;
    min-height: 600px;
  }
  @include respond-to ('l') {
    min-height: 800px;
  }
  display: flex;
  align-items: center;
}

.hero {
  @include respond-to('m') {
    margin-left: -12px;
    margin-right: -13px;
  }
}

.owl-carousel {
  height: 560px;
  overflow: hidden;
  @include respond-to ('s'){
    height: auto;
  }
}

.front h1.page-title {
  display: none;
}

#featured-on{
  width: 270px;
}

#home-ipad {
  display: none;
  @include respond-to ('m') {
    width: 60%;
    margin: 5rem auto;
    display: block;
  }

  position: relative;

  -webkit-transform: rotate(13deg);
  -ms-transform: rotate(13deg);
  transform: rotate(13deg);
}


#home-hero .copy {
  @include respond-to ('s') {
    width:60%;
  }
  @include respond-to ('m') {
    left: 10%;
    width: 50%;
  }
  @include respond-to ('l') {
    width: 40%;
  }
  position: relative;
  align-self: center;
}

ul.checkboxes {
  list-style: none;
  position: relative;
  @include respond-to ('m') {
    font-size: 1.2rem;
  }
  @include respond-to ('l') {
    font-size: 1.5rem;
  }
}

ul.checkboxes li {
  margin-bottom: 1.5rem;
}

ul.checkboxes li:before {
  content: '';
  background-image: url("/sites/all/themes/newlooknow/images/home/checkbox.png");
  background-size: 50%;
  background-repeat: no-repeat;
  display: block;
  height: 30px;
  width: 50px;
  position: absolute;
  left: -6px;
}

.home-step {
  background-image: url("../images/home/3_step_sprite.png");
  background-size: 860px;
  background-repeat: no-repeat;
  padding-top: 170px;
}

.home-step.step-1 {
  background-position: -14% 0;
  @include respond-to ('s') {
    background-position: -4% 0;
  }
  @include respond-to ('m') {
    background-position: -23% 0;
  }
}

.home-step.step-2 {
  background-position: 50% 0;
  @include respond-to ('s') {
    background-position: 49% 0;
  }
  @include respond-to ('m') {
    background-position: 49% 0;
  }

}

.home-step.step-3 {
  background-position: 110% 0;
  @include respond-to ('s') {
    background-position: 100% 0;
  }
  @include respond-to ('m') {
    background-position: 120% 0;
  }
}


#free-consultation .bg-image {
  padding-top: 7rem;
  padding-bottom: 9rem;
  margin-bottom: 2px;
  margin-left: -12px;
  margin-right: -13px;
  background-position: -1090px 0;
  @include respond-to ('s') {
    background-position: -710px 0;
  }
  @include respond-to ('m') {
    background-position: 0 -140px;
  }
}

.bg-image6 {
  background-image: url(../images/edited-portion/background-images/background-6.jpg);
}
.bg-image2 {
  background-image:url(../images/edited-portion/background-images/background-2.jpg);
}