
.node-type-landing-page {
  .hero {
    img {
      width:100%;
    }
  }
  .field-name-body {
    p {
      font-size:1.2rem;
    }
  }

}

